import {
  SET_PROJECT_OF_INTEREST,
  SET_PROJECTS_OPTIONS_FILTER
} from './actions'

export const initialState = {
  projectOfInterest: undefined,
  projectOptionsFilter: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_OF_INTEREST:
      return {
        ...state,
        projectOfInterest: action.projectOfInterest
      }

    case SET_PROJECTS_OPTIONS_FILTER:
      return {
        ...state,
        projectOptionsFilter: action.projectOptionsFilter
      }

    default:
      return state
  }
}

export default reducer
