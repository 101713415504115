export const SET_PROJECT_OF_INTEREST = 'SET_PROJECT_OF_INTEREST'

export const setProjectOfInterest = (project) => {
  return {
    type: SET_PROJECT_OF_INTEREST,
    projectOfInterest: project
  }
}

export const SET_PROJECTS_OPTIONS_FILTER = 'SET_PROJECTS_OPTIONS_FILTER'

export const setProjectsOptionsFilter = (filter) => {
  return {
    type: SET_PROJECTS_OPTIONS_FILTER,
    projectOptionsFilter: filter
  }
}
